import React, {useState} from 'react';
import SalesStats from "./SalesStats";
import TabNavigator from "../../../components/TabNavigator";
import AllSales from "./AllSales";
import PlanSales from "./PlanSales";
import ProductSales from "./ProductSales";
import {Member} from "../../Members";
import CashExpenses from "./CashExpenses";

export enum SaleLocations {POS = "CS", IN_APP = "IA"}

interface SalePlanItem {
    id: string;
    quantity: number;
    price: string;
    plan: string;
    cancelled: boolean;
}

export interface SaleProductItem {
    id: string;
    quantity: number;
    price: string;
    product: string;
    cancelled: boolean;
}

export enum SaleItemType {fee, plan, product, registrationFee}

export interface SaleItem {
    id: string;
    item_type: SaleItemType;
    name: string;
    quantity: number;
    price: number;
    discount_amount?: number;
    total: number;
    ref: string;
}

export interface Sale {
    id: string;
    status: number,
    plan_items: SalePlanItem[];
    products: SaleProductItem[];
    items: SaleItem[];
    total: string;
    member: Member;
    payment_method?: string;
    branch: string;
    card: string;
    completed: boolean;
    transaction_by: string;
    created_on: string
    expiration?: string
    location: SaleLocations,
    paid_on?: string;
    discount?: string;
    pos_discount?: string;
}


const SalesReports = () => {
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(new Date());
    return (
        <div>
            <SalesStats rangeStart={rangeStart} rangeEnd={rangeEnd} setRangeStart={setRangeStart}
                        setRangeEnd={setRangeEnd}/>
            <div className="border-top"/>
            <div className="rounded shadow-sm px-2 pt-2" style={{background: "white"}}>
                <TabNavigator tabLabels={["Ventas", "Planes", "Productos", "Movimientos de efectivo"]} panels={
                    [
                        <AllSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                        <PlanSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                        <ProductSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                        <CashExpenses rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                    ]

                }/>

            </div>
            <div className="pb-48"/>
        </div>
    );
};

export default SalesReports;
