import React, {useState} from 'react';
import CheckInPill from "../../../pages/Reservations/CheckInPill";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {Reservation} from "../../../pages/Reservations";
import DeleteModal from "../../modals/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {TimeSlotV2} from "../../../pages/Schedules";
import {format, isBefore, parseISO} from "date-fns";
import {updateReservation} from "../../../actions/timeslotReservations";
import {
    faBirthdayCake,
    faBriefcaseMedical,
    faEdit,
} from "@fortawesome/free-solid-svg-icons";
import {es} from "date-fns/locale";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {useHistory} from "react-router-dom";
import {Room} from "../../../pages/Toolbox/Rooms";

type ReservationTrProps = {
    rsvp: Reservation
    room?: Room
    editDisabled: boolean
    onEditSpotClick: (rsvp: Reservation) => void
}

const ReservationTr = ({rsvp, onEditSpotClick, room, editDisabled = false}: ReservationTrProps) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const timeslot = useSelector<StoreState, TimeSlotV2>(state => state.timeSlotReservations.timeslot)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [showDelete, setShowDelete] = useState(false);
    const [showDisabledTooltip, setShowDisabledTooltip] = useState(false);
    const [showDisabledDeleteTooltip, setShowDisabledDeleteTooltip] = useState(false);
    const isBirthdayToday = rsvp.member?.birthday ? parseISO(rsvp.member?.birthday).getDate() === timeslot.start.getDate() && parseISO(rsvp.member.birthday).getMonth() === timeslot.start.getMonth() : false
    const birthdayTooltip = (props: any) => (
        <Tooltip id="birthdayTT" {...props}>
            {rsvp.member?.full_name} cumple años en este día.
        </Tooltip>
    );

    const editDisabledTooltip = (props: any) => {
        return <Tooltip id="editDisabledTT" {...props}>
            No cuentas con permiso para esta acción. Solicitalo a tu administrador.
        </Tooltip>
    };

    return (
        <tr
            key={rsvp.id}
            className={`font-size-sm align-middle text-center ${rsvp.cancelled ? "bg-secondary" : ""}`}>
            <DeleteModal
                url={`/rsvp/update/${rsvp.id}/`}
                onSuccess={() => {
                    dispatch(updateReservation({...rsvp, cancelled: true}))
                }}
                show={showDelete}
                onHide={() => setShowDelete(false)}
                text={"Cancelar Reservación"}
                successText={"Reservación cancelada correctamente."}
            >
                <div>
                    {rsvp.member ?
                        <p className="p-0 m-0">Cliente: {rsvp.member.full_name} {rsvp.member.last_name ?? ''} ({rsvp.member.external_id})</p>
                        :
                        <p className="p-0 m-0">Cliente: {rsvp.dashboard_reservation?.full_name} ({rsvp.dashboard_reservation?.phone})</p>
                    }
                    <p className="p-0 m-0">Clase: {timeslot.studio_class_name}</p>
                    <p className="p-0 m-0">Horario: {format(timeslot.start, "dd/MMM/yyyy h:mm a", {locale: es})}</p>
                </div>
            </DeleteModal>

            <td className=" text-uppercase align-middle font-size-lg text-muted px-0"> {rsvp.member ? rsvp.member.external_id : ""}</td>
            <td onClick={!rsvp.member ? undefined : () => history.push(`/users/${rsvp.member!.id}`)}
                className={`align-middle text-left ${rsvp.member ? 'clickable' : ''}`}>
                <div className=" font-size-lg">
                    {rsvp.member ? `${rsvp.member.full_name} ${rsvp.member.last_name ?? ''}` : rsvp.dashboard_reservation?.full_name}
                    <OverlayTrigger placement="bottom" overlay={birthdayTooltip} delay={{show: 150, hide: 250}}>
                        <i hidden={!isBirthdayToday} className="text-success ml-2">
                            <FontAwesomeIcon icon={faBirthdayCake}/>
                        </i>
                    </OverlayTrigger>
                    {/* MEMBER INJURIES */}
                    {rsvp.member?.injuries ? <span
                        className="ml-2 label label-inline label-light-danger label-pill font-weight-bolder font-size-lg">
                            <FontAwesomeIcon className="mr-1" icon={faBriefcaseMedical}/> {rsvp.member?.injuries}
                        </span> : <React.Fragment/>}
                    {/* MULTA PENDIENTE */}
                    {rsvp.fees_amount ?
                        <span
                            className="ml-2 label label-inline label-light-danger label-pill font-weight-bolder font-size-lg">
                            Multa {formatCurrency(rsvp.fees_amount ?? 0, currency)}
                        </span>
                        : <React.Fragment/>}
                    {/* CLIENTE NUEVO */}
                    {rsvp.is_first ?
                        <span
                            className="ml-2 label label-inline label-light-primary label-pill font-weight-bolder font-size-lg">
                            Cliente nuevo
                        </span>
                        : <React.Fragment/>}

                    {/* DEUDA */}
                    {rsvp.has_debt ?
                        <span
                            className="ml-2 label label-inline label-light-warning label-pill font-weight-bolder font-size-lg">
                            Deuda
                        </span>
                        : <React.Fragment/>
                    }
                    {rsvp.gympass_id ? <span
                        className="ml-2 label label-inline label-light-danger label-pill font-weight-bolder font-size-lg">
                            GymPass
                        </span> : <React.Fragment/>}
                </div>
                <div className="text-muted font-size-xs">{rsvp.email || rsvp.dashboard_reservation?.email} </div>
            </td>

            <td className=" font-size-lg">{rsvp.seat}
                {isBefore(new Date(), timeslot.start) ?
                    <Button onClick={() => onEditSpotClick(rsvp)} className="m-0 p-0 pb-1 align-content-center"
                            hidden={!room?.uses_map || editDisabled} variant="link" size="sm"><FontAwesomeIcon
                        icon={faEdit}/></Button>
                    : <React.Fragment/>
                }
            </td>
            <td className=" font-size-lg">{rsvp.member ? rsvp.member.shoe_size : ""}</td>
            <td className=" px-0">

                <OverlayTrigger onToggle={(val) => {
                    if (editDisabled) {
                        setShowDisabledTooltip(val)
                    }
                }} placement="bottom" overlay={editDisabledTooltip} delay={{show: 200, hide: 250}}
                                show={showDisabledTooltip}>
                    <div>
                        <CheckInPill rsvp={rsvp} editDisabled={editDisabled}/>
                    </div>
                </OverlayTrigger>
            </td>
            <td>
                <OverlayTrigger onToggle={(val) => {
                    if (editDisabled) {
                        setShowDisabledDeleteTooltip(val)
                    }
                }} placement="bottom" overlay={editDisabledTooltip} delay={{show: 200, hide: 250}}
                                show={showDisabledDeleteTooltip}>
                    <div>

                        <Button
                            hidden={timeslot.archived}
                            disabled={useSelector<StoreState, boolean>(state => !state.staffUser.permissions.find(p => p.feature === "reservations")?.delete ?? true)}
                            className="btn btn-icon btn-light btn-xs btn-hover-danger mr-1"
                            onClick={() => {
                                setShowDelete(true)
                            }}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </Button>
                    </div>
                </OverlayTrigger>
                {/*<Button*/}
                {/*    className="btn btn-icon btn-light btn-xs btn-hover-primary">*/}
                {/*    <FontAwesomeIcon icon={faUser}/>*/}
                {/*</Button>*/}
            </td>
        </tr>
    )
        ;
};

export default ReservationTr;
