import React, {useState} from 'react';
import {Formik, Form as FormikForm} from "formik";
import FormField from "../../../components/form/FormField";
import {Alert, Button} from "react-bootstrap";

import ToolboxHeader from "../ToolboxHeader";
import baseApi from "../../../apis/baseApi";
import yup from "../../../utils/yup";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch, updateBranches} from "../../../actions";
import KeenToggle from "../../../components/misc/KeenToggle";


const GympassPreferences = () => {
    const dispatch = useDispatch()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");


    const ValidationSchema = yup.object().shape({
        gympass_gym_id: yup.number().integer().required(),
    })

    return (
        <div className="p-5">
            <ToolboxHeader title="GymPass"/>
            <Alert variant={success ? "success" : "danger"} hidden={success === null}>{alertMsg}</Alert>
            <div className="pr-5">
                <Formik
                    enableReinitialize
                    validationSchema={ValidationSchema}
                    initialValues={{
                        gympass_gym_id: branch.gympass_gym_id,
                        gympass_active: branch.gympass_active,
                        gympass_automated_checkin: branch.gympass_automated_checkin
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        if (!branch.gympass_active) {
                            values.gympass_active = false
                        }
                        baseApi.patch(`/studios/branch/${branch.id}/`, values).then((resp) => {
                            setAlertMsg("Cambios guardados")
                            dispatch(updateBranches(branches.map((b) => b.id === branch.id ? resp.data : b)))
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch((err) => {
                            setAlertMsg("Error")
                            setSuccess(false)
                            setSubmitting(false)
                        })

                    }
                    }>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <div className="offset-lg-1 font-size-h6 font-weight-bold">Sucursal {branch.name}</div>
                            <div className="offset-lg-1">
                                <div>
                                    <div className="row align-items-center">
                                        <div className="col-lg-2" >GymPass Activo</div>
                                        <div className="col-lg-4">
                                            <KeenToggle checked={values.gympass_active}
                                                        onChange={(e) => setFieldValue("gympass_active", e.target.checked)}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row align-items-center">
                                        <div className="col-lg-2" >Check-In Automático</div>
                                        <div className="col-lg-4">
                                            <KeenToggle checked={values.gympass_automated_checkin}
                                                        onChange={(e) => setFieldValue("gympass_automated_checkin", e.target.checked)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormField label="Gym ID" name="gympass_gym_id"/>

                            <button onClick={() => {
                                window.open("https://gympass.com/es-mx/integrate-with-gympass/", '_blank')
                            }} className="btn btn-link offset-lg-1">Conocer mas sobre GymPass
                            </button>
                            <div className="text-right">
                                <Button disabled={isSubmitting}
                                        type="submit">{isSubmitting ? "..." : "Guardar"} </Button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            </div>
            <hr/>
        </div>
    )
        ;
};

export default GympassPreferences;
