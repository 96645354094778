import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import {Button, Dropdown, DropdownButton, Image} from "react-bootstrap";

import {StoreState} from "../reducers";
import {StaffUserPermissions} from "../actions/staffUser";
import {Studio} from "../actions/studio";
import "../assets/styles/sidebar.css";
import "../assets/styles/sidebarActive.css"

import {Branch, BranchesState, logout, selectBranch} from "../actions";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";


interface SidebarProps {
    showSidebar: boolean
}

const Sidebar = ({showSidebar}: SidebarProps) => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const permissions = useSelector<StoreState, StaffUserPermissions[]>(state => state.staffUser.permissions)

    // AGREGADO T
    const dispatch = useDispatch()
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    // AGREGADO T

    const noNotificationStudios = [
        "6b1025bb-2d07-4f43-b9be-1813e02eaf9e", // MANTRA
        "ef4d8e53-7f76-42a4-982b-334cebafbd0d", // SPEEDCYCLE
        "25c300e1-212a-4609-a40c-33e6f5402dd0", // ROLLER SHAPE
        // "a202b831-ee68-4715-8d95-71d305bdb6c5", // RAISE PERU
        // "b788fce4-89bd-4d41-b966-7b2460c3ea9e", // Emotion
        // "4e8c8da6-764a-4d3d-b106-4c247901db3a", // Harmony
        "771fa439-bd6d-4038-86b3-aed5ef639cf7", // VAM
        "41c3b069-5eed-43f2-a069-ee0fb4b53ca9", // BIKE POWER
        "4d421bbe-9de2-45ef-a408-c732cb543ea7", // GABY FRANCO
        "d10c00b6-3565-41b9-8032-b2b534550c9a", // REFORCE
        // "8772ddbc-bdf1-41fe-b882-57d0cfa5e9ef", // RUSH RIDE
    ]

    let hideReservations = true
    let hideSchedules = true
    let hideClients = true
    let hideNotifications = true
    let hideReports = true
    let hidePersonalization = true
    let hideBilling = true
    let hideCoaches = true
    let hidePos = true

    permissions.forEach((p) => {
        switch (p.feature) {
            case "pos":
                hidePos = !p.view
                break
            case "reservations":
                hideReservations = !p.view
                break
            case "schedules":
                hideSchedules = !p.view
                break
            case "clients":
                hideClients = !p.view
                break
            case "notifications":
                if (noNotificationStudios.includes(studio.id)) {
                    hideNotifications = true
                } else {
                    hideNotifications = !p.view
                }
                break
            case "reports":
                hideReports = !p.view
                break
            case "personalization":
                hidePersonalization = !p.view
                break
            case "billing":
                hideBilling = !p.view
                break
            case "coaches":
                hideCoaches = !p.view
                break
        }
    })

    return (
        <nav id="sidebar" className={showSidebar ? "active" : ""} style={{height: "100%"}}>
            

            {/* DESKTOP */}
                        {/* DESKTOP */}
            {/* DESKTOP */}

            <div className="sidebar-header text-center text-primary d-none d-md-block">
                <Image
                    className="shadow p-1"
                    src={studio.image}
                    roundedCircle
                    fluid
                />
            </div>
            <ul className="list-unstyled components  d-none d-md-block">

                <li className="">
                    <NavLink exact to="/" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-home"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Dashboard</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideReservations}>
                    <NavLink to="/reservations" activeClassName="active">
                        <div className="d-flex">
                            <i className="mr-5 fi-rr-list-check"/>
                            <span className="font-size-lg font-weight-normal text-dark-50 ">Reservaciones</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideSchedules} className="">
                    <NavLink exact to="/schedules" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-calendar"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Calendario</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideClients}>
                    <NavLink to="/users" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-user"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Clientes</span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hideReservations || !currentBranch.gympass_active}>
                    <NavLink to="/gympass-checkins" activeClassName="active">
                        <div className="d-flex">
                            <i> <FontAwesomeIcon className="text-dark-50" icon={faList} /></i>
                            <span className="font-size-lg font-weight-normal text-dark-50 d-inline-flex">CheckIn<span className="font-size-xs text-danger ml-1">GP</span></span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hidePos}>
                    <NavLink to="/pos" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-shopping-cart"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Vender <span className="font-size-xs ml-2">POS</span></span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hidePos}>
                    <NavLink to="/cashier" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-dollar"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Caja</span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hideNotifications}>
                    <NavLink to="/notifications" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-megaphone"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Notificaciones</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideCoaches}>
                    <NavLink to="/coaches" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-gym"/>
                            <span className="font-size-lg font-weight-normal text-dark-50">Coaches</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideReports} className="">
                    <NavLink to="/reports" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-stats">
                            </i>
                            <span className="font-size-lg font-weight-normal text-dark-50">Reportes</span>
                        </div>
                    </NavLink>
                </li>


                <li hidden={hidePersonalization} className="settings">
                    <NavLink to="/toolbox" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-5 fi-rr-settings">
                            </i>
                            <span className="font-size-lg font-weight-normal text-dark-50">Personalización</span>
                        </div>
                    </NavLink>
                </li>

                {/* boton blanco */}
                <Link to="website-app">
                    <div className="d-flex mt-5">
                        <div className="px-3"></div>
                        <button
                            className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark font-size-md">
                            <div>
                                <i className="fi-rr-info text-muted"></i>
                                Website y App
                            </div>
                            {/* <img src="../src/assets/img/app-store.png" alt="" /> */}
                        </button>
                        <div className="px-3"></div>
                    </div>
                </Link>

            </ul>
            {/* FIN DESKTOP */}


                {/* MOBILE */}
                {/* MOBILE */}
                {/* MOBILE */}
                {/* MOBILE */}
                {/* MOBILE */}
                {/* MOBILE */}
                {/* MOBILE */}
                {/* MOBILE */}

            <ul className="list-unstyled components d-md-none">
            <Image
                    className="shadow p-1 d-md-none w-50"
                    src={studio.image}
                    roundedCircle
                    fluid
                />

                <li className="">
                    <NavLink exact to="/" activeClassName="active">
                        <div className="d-flex ">
                            <i className="text-dark-50 mr-3 fi-rr-home icon-md ml-3 mt-2"/>
                            <div className="font-weight-normal text-dark-50 pt-3">Dashboard</div>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideReservations}>
                    <NavLink to="/reservations" activeClassName="active">
                        <div className="d-flex">
                            <i className="mr-3 fi-rr-list-check icon-md ml-3 mt-2"/>
                            <span className="font-weight-normal text-dark-50 pt-3 ">Reservaciones</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideSchedules} className="">
                    <NavLink exact to="/schedules" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-calendar icon-md ml-3 mt-2"/>
                            <span className="font-weight-normal text-dark-50 pt-3">Calendario</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideClients}>
                    <NavLink to="/users" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-user  icon-md ml-3 mt-2"/>
                            <span className="font-weight-normal text-dark-50 pt-3">Clientes</span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hidePos}>
                    <NavLink to="/pos" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-shopping-cart  icon-md ml-3 mt-2"/>
                            <span className="font-weight-normal text-dark-50 pt-3">Vender <span className="font-size-xs ml-2">POS</span></span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hidePos}>
                    <NavLink to="/cashier" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-dollar icon-md ml-3 mt-2"/>
                            <span className="ont-weight-normal text-dark-50 pt-3">Caja </span>
                        </div>
                    </NavLink>
                </li>
                <li hidden={hideNotifications}>
                    <NavLink to="/notifications" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-megaphone  icon-md ml-3 mt-2"/>
                            <span className="font-weight-normal text-dark-50 pt-3">Notificaciones</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideCoaches}>
                    <NavLink to="/coaches" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-gym icon-md ml-3 mt-2"/>
                            <span className="font-weight-normal text-dark-50 pt-3">Coaches</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideReports} className="">
                    <NavLink to="/reports" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-stats  icon-md ml-3 mt-2">
                            </i>
                            <span className="font-weight-normal text-dark-50 pt-3">Reportes</span>
                        </div>
                    </NavLink>
                </li>


                <li hidden={hidePersonalization} className="settings">
                    <NavLink to="/toolbox" activeClassName="active">
                        <div className="d-flex">
                            <i className="text-dark-50 mr-3 fi-rr-settings  icon-md ml-3 mt-2">
                            </i>
                            <span className="font-weight-normal text-dark-50 pt-3">Personalización</span>
                        </div>
                    </NavLink>
                </li>


                {/* MOBILE SUCURSAL */}
                    <DropdownButton id="dropdown-basic-button" title={"Sucursal " + currentBranch.name}
                                        size="sm"
                                        bsPrefix={"btn btn-pill text-muted pl-6 border border-muted font-size-xs"}
                                        >
                            {branchesState.branches.map((b) => {
                                return <Dropdown.Item key={b.id}
                                                    onClick={() => dispatch(selectBranch(b))}>{b.name}
                                </Dropdown.Item>
                            })}
                        </DropdownButton>
                {/* FIN MOBILE SUCURSAL */}

                

                {/* CERRAR SESION MOBILE*/}
                    <Button onClick={() => {
                        dispatch(logout())
                    }} className="btn btn-white btn-icon btn-circle shadow mt-40">
                        <i className="fi-rr-sign-out text-dark-50" />
                    </Button>
            </ul>
            {/* FIN MOBILE */}


            {/*  <div className="text-center d-none d-md-block">*/}
            {/*      <OverlayTrigger*/}
            {/*          placement="right"*/}
            {/*          overlay={*/}
            {/*              <Tooltip id="sidebarTooltip">*/}
            {/*                  {`${close ? "Maximizar" : "Minimizar"} sidebar`}*/}
            {/*              </Tooltip>*/}
            {/*          }*/}
            {/*      >*/}
            {/*<span className="" style={{cursor: "pointer", fontSize: "1.5rem"}}>*/}
            {/*  <FontAwesomeIcon*/}
            {/*      onClick={() => {*/}
            {/*          // setClose(!close);*/}
            {/*          // setOpenSidebar(close);*/}
            {/*      }}*/}
            {/*      icon={close ? faArrowAltCircleRight : faArrowAltCircleLeft}*/}
            {/*  />*/}
            {/*</span>*/}
            {/*      </OverlayTrigger>*/}
            {/*  </div>*/}


            {/* <div className="d-flex pt-30">
                <div className="px-3"></div>
                <button className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark">Planes y Precios</button>
                <div className="px-3"></div>
            </div> */}


            {/*<Link style={{textDecoration: "none"}} to="/billing">*/}
            {/*    <div hidden={hideBilling || studio.active} className="px-5 mt-10">*/}
            {/*        <button*/}
            {/*            className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark font-size-md">*/}
            {/*            <div>Planes y Precios</div>*/}
            {/*            <div className="label label-light-danger label-pill label-inline mt-2 font-weight-bold">50%*/}
            {/*            </div>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <div hidden={hideBilling || !studio.active} className="px-5 mt-10">*/}
            {/*        <button*/}
            {/*            className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark font-size-md">*/}
            {/*            <div>Suscripción</div>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</Link>*/}


            {/* <div className="d-flex justify-content-between">
                <div className="px-3"></div>
                <button className="btn btn-clean btn-icon btn-circle ">
                    <i className="fi-rr-portrait text-dark-50"></i>
                </button>
                <button className="btn btn-clean btn-icon btn-circle ">
                    <i className="fi-rr-interrogation text-dark-50"></i>
                </button>
                <div className="px-3"></div>
            </div> */}
           

           

        </nav>
    );
};


export default Sidebar;