import {StudioClassesTypes} from "./types";
import {Dispatch} from "redux";
import baseApi from "../apis/baseApi";

export interface StudioClass {
    id: string;
    name: string;
    color: string;
    allow_manual_credits: boolean;
    gympass_config: StudioClassGympassConfig | null;
    categories: string[]
}

export interface StudioClassGympassConfig {
    id: string;
    visible: boolean;
    bookable: boolean;
    description: string;
    branch_configs: StudioClassGympassBranchConfig[]
}

export interface StudioClassGympassBranchConfig {
    branch: string;
    gympass_id: number;
    product_id: number;
}

export interface StudioClassCategory {
    id: string;
    name: string;
}

export interface StudioClassesState {
    loading: boolean
    error: string
    classes: StudioClass[]
}

export interface StudioClassesActions {
    type: StudioClassesTypes
    payload: any
}

export const fetchStudioClasses = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get<StudioClass[]>("/studios/classes/").then((response) => {
            dispatch<StudioClassesActions>({
                type: StudioClassesTypes.FETCHED,
                payload: response.data
            })
        })
    }
}

export const updateClass = (studioClass: StudioClass) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: StudioClassesTypes.UPDATE_CLASS,
            payload: studioClass
        })
    }
}

export const addClass = (studioClass: StudioClass) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: StudioClassesTypes.ADD_CLASS,
            payload: studioClass
        })
    }
}

export const removeClass = (studioClassId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: StudioClassesTypes.REMOVE_CLASS,
            payload: studioClassId
        })
    }
}